import React from 'react';
import { DataSyncExecution } from '../../../types/DataSyncExecution';
import styles from './DataSyncExecutionList.module.sass';
import { Icon, Card } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { DataTablePaging, DataTablePagingProps } from '@zaiusinc/hera';
import { useObserver } from 'mobx-react-lite';
import { Accordion } from '@zaiusinc/hera';
import DataSyncDetailsStore from '../../../stores/DataSyncDetailsStore';
import { LoadingIndicator } from '../../../components/LoadingIndicator';

interface DataSyncExecutionListProps {
  detailStore: DataSyncDetailsStore;
}

const statusToClassMap = {
  SYNC_STATUS_NOT_SET: styles.notSet,
  PENDING: styles.pending,
  EXPORT_REQUESTED: styles.exportRequested,
  EXPORT_READY: styles.exportReady,
  IN_PROGRESS: styles.inProgress,
  COMPLETED: styles.completed,
  FAILED: styles.failed,
};

const DataSyncExecutionList: React.FC<DataSyncExecutionListProps> = ({detailStore}) => {
  return useObserver(() => {
    const {executions, loading} = detailStore;

    const renderEmptyState = () => (
      <div className={styles.emptyList}>
        <Icon icon={IconNames.SEARCH} iconSize={64}/>
        <p>No executions were found</p>
      </div>
    );

    const renderExecutionCard = (execution: DataSyncExecution) => {
      const createdAt = new Date(execution.created_at);
      const statusClass = statusToClassMap[execution.status] || styles.notSet;
      const header = (
        <div key={execution.id} className={`${styles.cardStatus} ${statusClass}`}>
          {execution.status}
        </div>
      );

      return (
        <Card interactive={false} key={execution.id} className={styles.card}>
          <Accordion header={header} minimal={true} disabled={!execution.message}>
            {execution.message}
          </Accordion>
          <div className={styles.cardDateTime}>
            {createdAt.toLocaleString()}
          </div>
        </Card>
      );
    };

    return (
      <div>
        <DataTablePaging
          rowStart={detailStore.executionsPaging.rowStart}
          rowCount={detailStore.executionsPaging.rowCount}
          total={detailStore.executionsPaging.total}
          onPaging={async (pagingProps: DataTablePagingProps) => {
            await detailStore.setExecutionsPaging(pagingProps);
          }}
          hideDropDown={false}
        />
        {loading ?
          <LoadingIndicator/> : executions.length === 0 ? renderEmptyState() : executions.map(renderExecutionCard)}
      </div>
    );
  });
};

export default DataSyncExecutionList;
