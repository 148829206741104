import { SelectItem } from '@zaiusinc/hera';
import { observable } from 'mobx';
import { ZipApi } from '../lib/ZipApi';
import { directoryStore } from './DirectoryStore';
import { ObjectDefinition, ObjectFieldDefinition } from '../types/DataSyncItem';

class DataSyncOdpObjectsStore {
  @observable public loading = false;

  @observable private _objectDefinitions: ObjectDefinition[] = [];

  public async fetch() {
    this.loading = true;
    if (directoryStore.trackerId) {
      this._objectDefinitions = await ZipApi.listODPSchemas(directoryStore.trackerId);

    }
    this.loading = false;
  }

  public getObjectList(): SelectItem[] {
    return this._objectDefinitions.map((object) => {
      return {
        text: object.object_display_name,
        value: object.object_name,
      };
    });
  }

  public getObjectFieldList(objectName: string): ObjectFieldDefinition[] {
    const object = this._objectDefinitions.find((obj) => obj.object_name === objectName);
    if (!object) {
      return [];
    }
    return object.fields;
  }
}

export const dataSyncOdpObjectsStore = new DataSyncOdpObjectsStore();
