import { SelectItem } from '@zaiusinc/hera';
import { observable } from 'mobx';
import { ZipApi } from '../lib/ZipApi';
import { directoryStore } from './DirectoryStore';
import { ProductInstances } from '../types/DataSyncItem';

class DataSyncProductsStore {
  @observable public loading = false;

  @observable private _productInstances: ProductInstances[] = [];

  public async fetch() {
    this.loading = true;
    if (directoryStore.trackerId) {
      this._productInstances = await ZipApi.listProductInstances(directoryStore.trackerId!);
    }
    this.loading = false;
  }

  public getProductList(): SelectItem[] {
    return this._productInstances.map((product) => {
      return {
        text: product.product,
        value: product.product,
      };
    });
  }

  public getInstanceList(productName: string): SelectItem[] {
    const product = this._productInstances.find((obj) => obj.product === productName);
    if (!product) {
      return [];
    }
    return product.instances.map((instance) => {
      return {
        text: instance.name,
        value: instance.id,
      };
    });
  }
}

export const dataSyncProductsStore = new DataSyncProductsStore();
