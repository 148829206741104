import { Button, Icon } from '@blueprintjs/core';
import { useObserver } from 'mobx-react';
import React, { useRef, useState } from 'react';
import { dataSyncOdpObjectsStore } from '../../../stores/DataSyncOdpObjectsStore';
import { DataSyncItem } from '../../../types/DataSyncItem';
import styles from './DataSyncForm.module.sass';
import FieldMappingsComponent from './FormSections/FieldMappingsComponent';
import { contentGraphStore } from '../../../stores/DataSyncCGStore';
import DataSyncFormSource from './FormSections/DataSyncFormSource';
import DataSyncFormDestination from './FormSections/DataSyncFormDestination';
import { IconNames } from '@blueprintjs/icons';
import DataSyncScheduleSection from './FormSections/DataSyncScheduleSection';
import DataSyncConfigSection from './FormSections/DataSyncConfigSection';
import { ValidateHandle } from './FormSections/ValidateHandle';

export interface DataSyncCreateProps {
  mode: 'create' | 'edit';
  onSubmit: (syncObj: DataSyncItem) => void;
  onCancel: () => void;
  initialValues: DataSyncItem;
}

const DataSyncForm = ({onSubmit, onCancel, initialValues}: DataSyncCreateProps) => {
  const [dataSync, setDataSync] = useState<DataSyncItem>({...initialValues});

  const configRef = useRef<ValidateHandle>(null);
  const sourceRef = useRef<ValidateHandle>(null);
  const destinationRef = useRef<ValidateHandle>(null);
  const mappingsRef = useRef<ValidateHandle>(null);
  const scheduleRef = useRef<ValidateHandle>(null);

  const isValid = () => {
    const isConfigValid = configRef.current?.validate() ?? false;
    const isSourceValid = sourceRef.current?.validate() ?? false;
    const isDestinationValid = destinationRef.current?.validate() ?? false;
    const isMappingsValid = mappingsRef.current?.validate() ?? false;
    const isScheduleValid = scheduleRef.current?.validate() ?? false;

    return isConfigValid && isSourceValid && isDestinationValid && isMappingsValid && isScheduleValid;
  };

  return useObserver(() => (
    <>
      <div className={styles.header}>
        <div>To create a new sync, define a source and destination. Then setup field mapping</div>
        <div className={styles.actionButtons}>
          <Button
            text="Cancel"
            onClick={onCancel}
          />
          <Button
            text="Save"
            intent="primary"
            onClick={() => {
              if (isValid()) {
                onSubmit(dataSync);
              }
            }}
          />
        </div>
      </div>
      <div className={styles.container}>
        <DataSyncConfigSection
          value={{name: dataSync.name}} ref={configRef}
          onChange={(obj) => {
          dataSync.name = obj.name;
          setDataSync({...dataSync});
        }} />

        <div className={styles.row}>
          <DataSyncFormSource
            initialValue={dataSync.source} ref={sourceRef}
            onChange={(value) => {
              dataSync.source = value;
              setDataSync({...dataSync});
            }}
          />
          <div className={styles.arrowRight}>
            <Icon icon={IconNames.ARROW_RIGHT}/>
          </div>

          <DataSyncFormDestination
            initialValue={dataSync.destination} ref={destinationRef}
            onChange={(value) => {
              dataSync.destination = value;
              setDataSync({...dataSync});
            }}
          />
        </div>

        <FieldMappingsComponent
          ref={mappingsRef}
          fieldMappings={dataSync.field_mappings}
          sourceFields={dataSyncOdpObjectsStore.getObjectFieldList(dataSync.source.object_name)}
          destinationFields={contentGraphStore.getObjectFields(dataSync.destination.object_name)}
          onChange={(newFields) => {
            setDataSync({...dataSync, field_mappings: newFields});
          }}
        />

        <DataSyncScheduleSection
          value={dataSync.interval} ref={scheduleRef}
          onChange={(value: string) => {
            dataSync.interval = value;
            setDataSync({...dataSync});
          }}/>
      </div>
    </>
  ));
};

export default DataSyncForm;
