import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import styles from './DataSyncFormSection.module.sass';
import { DropDown, Label } from '@zaiusinc/hera';
import { dataSyncProductsStore } from '../../../../stores/DataSyncProductsStore';
import { contentGraphStore } from '../../../../stores/DataSyncCGStore';
import { DataSyncDestination } from '../../../../types/DataSyncItem';
import { useObserver } from 'mobx-react-lite';
import ErrorMessage from '../ErrorMessage';
import { ValidateHandle } from './ValidateHandle';

interface DataSyncFormDestinationProps {
  disabled?: boolean;
  onChange: (value: DataSyncDestination) => void;
  initialValue: DataSyncDestination;
}

const DataSyncFormDestination = forwardRef<ValidateHandle, DataSyncFormDestinationProps>(
  (props, ref) => {
    const {disabled, initialValue, onChange} = props;
    const [destination, setValue] = React.useState<DataSyncDestination>({...initialValue});
    const [errors, setErrors] = React.useState<{ [key: string]: boolean }>({
      product: false,
      instance: false,
      object: false,
    });

    useImperativeHandle(ref, () => ({
      validate: () => {
        setErrors({
          product: !destination.product,
          instance: !destination.instance.id,
          object: !destination.object_name,
        });
        return !!(destination.product && destination.instance.id && destination.object_name);
      },
    }));

    useEffect(() => {
      dataSyncProductsStore.fetch();
    }, []);

    useEffect(() => {
      if (destination?.instance?.id) {
        contentGraphStore.fetch(destination.instance.id);
      }
    }, [destination?.instance?.id]);

    return useObserver(() => (
      <div className={styles.container}>
        <Label label="Destination" disabled={disabled} labelInfo="(OPTIMIZELY PRODUCT)"
               subLabel="Select an Optimizely product as destination to sync data from external app.">
          <DropDown
            disabled={disabled}
            initialContent="Select Product"
            items={dataSyncProductsStore.getProductList()}
            loading={dataSyncProductsStore.loading}
            onItemSelect={function noRefCheck(item: any) {
              const newData = {...destination, product: item.value};
              setValue(newData);
              onChange(newData);
              setErrors({...errors, product: false});
            }}
            value={destination.product}
            width={390}/>
          <ErrorMessage message="Please select a product" show={errors.product}/>
        </Label>

        <Label label="Instance" disabled={disabled}>
          <DropDown
            disabled={disabled}
            initialContent="Select Instance"
            items={dataSyncProductsStore.getInstanceList(destination.product)}
            loading={dataSyncProductsStore.loading}
            onItemSelect={function noRefCheck(item: any) {
              const newData = {...destination};
              newData.instance = {id: item.value, name: item.text};
              setValue(newData);
              onChange(newData);
              setErrors({...errors, instance: false});
            }}
            value={destination.instance.id}
            width={390}/>
          <ErrorMessage message="Please select an instance" show={errors.instance}/>
        </Label>

        <Label label="Object" disabled={disabled}>
          <DropDown
            disabled={disabled}
            initialContent="Select Object"
            items={contentGraphStore.getObjectList()}
            loading={contentGraphStore.loading}
            onItemSelect={function noRefCheck(item: any) {
              const newData = {...destination, object_name: item.value};
              setValue(newData);
              onChange(newData);
              setErrors({...errors, object: false});
            }}
            value={destination.object_name}
            width={390}/>
          <ErrorMessage message="Please select an object" show={errors.object}/>
        </Label>
      </div>
    ));
  });

export default DataSyncFormDestination;
