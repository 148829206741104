import React from 'react';
import styles from './ErrorMessage.module.sass';

const ErrorMessage = ({message, show}) => {
  if (!show) {
    return null;
  }
  return <div className={styles.container}>{message}</div>;
};

export default ErrorMessage;
