import { action, autorun, computed, observable } from 'mobx';
import { directoryStore } from './DirectoryStore';
import { ZipApi } from '../lib/ZipApi';
import { DataSyncExecution } from '../types/DataSyncExecution';
import { DataTablePagingProps } from '@zaiusinc/hera';
import { DataSyncItem } from '../types/DataSyncItem';

export default class DataSyncDetailsStore {
  @observable public loading = false;
  @observable public executions: DataSyncExecution[] = [];
  @observable public dataSync: DataSyncItem | null = null;

  @observable private _executionsPaging: DataTablePagingProps = {rowStart: 1, rowCount: 10, total: 1000};

  constructor(public dataSyncId: string) {
    autorun(() => {
      if (directoryStore.trackerId) {
        this.fetch();
      }
    });
  }

  public async fetch() {
    this.loading = true;
    const { dataSync} = (await ZipApi.getDataSync(this.dataSyncId));
    this.dataSync = dataSync;
    this.loading = false;
  }

  @action
  public async getExecutions() {
    this.loading = true;

    const offset = Math.floor(this._executionsPaging.rowStart / this._executionsPaging.rowCount);
    const response = await ZipApi.listDataSyncExecutions(
      this.dataSyncId,
      offset,
      this._executionsPaging.rowCount
    );

    this.executions = response.executions || [];
    this.loading = false;
  }

  @computed
  public get executionsPaging() {
    return this._executionsPaging;
  }

  @action
  public async setExecutionsPaging(paging: DataTablePagingProps) {
    if (paging.rowStart <= 0) {
      paging.rowStart = 1;
    }
    this._executionsPaging = paging;
    await this.getExecutions();
  }
}
