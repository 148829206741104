import { ZipApi } from '../lib/ZipApi';
import { action, computed, observable, reaction } from 'mobx';
import { DataSyncItem } from '../types/DataSyncItem';
import { directoryStore } from './DirectoryStore';
import { DataTablePagingProps } from '@zaiusinc/hera';

export class DataSyncStore {
  @observable public searchFilter: string = '';
  @observable public loading = false;

  @observable private _allDataSyncs: DataSyncItem[] = [];
  @observable private _paging: DataTablePagingProps = {rowStart: 1, rowCount: 10, total: 1000};

  constructor() {
    reaction(() => directoryStore.trackerId, () => {
      if (directoryStore.trackerId) {
        this.fetch();
      }
    });
  }

  public async fetch() {
    this.loading = true;

    const offset = Math.floor(this._paging.rowStart / this._paging.rowCount);
    const response = await ZipApi.listDataSyncs(
      offset, this._paging.rowCount, directoryStore.trackerId);

    this._allDataSyncs = response.dataSyncItems?.map((item) => new DataSyncItem(item)) || [];
    this.loading = false;
  }

  @action
  public async toggleDataSync(dataSyncId: string) {
    const dataSync = this._allDataSyncs.find((item) => item.id === dataSyncId);
    if (dataSync) {
      const func = dataSync.enabled ? ZipApi.disableDataSync.bind(ZipApi) : ZipApi.enableDataSync.bind(ZipApi);
      if (await func(dataSyncId)) {
        dataSync.enabled = !dataSync.enabled;
      }
    }
  }

  @computed
  public get dataSyncs(): DataSyncItem[] {
    const searchTerm = this.searchFilter.trim().toLowerCase();

    if (!searchTerm) {
      return this._allDataSyncs;
    }

    return this._allDataSyncs.filter(({ name, source, destination }: DataSyncItem) =>
      [name, source.object_name, destination.object_name]
        .some((field) => field.toLowerCase().includes(searchTerm))
    );
  }

  @action
  public async createDataSync(dataSync: DataSyncItem) {
    this.loading = true;
    const response = await ZipApi.createDataSync(dataSync);
    this._allDataSyncs.unshift(response.dataSync);
    this.loading = false;
  }

  @action
  public async getDataSync(dataSyncId: string) {
    this.loading = true;
    const { dataSync} = (await ZipApi.getDataSync(dataSyncId));
    this.loading = false;

    return dataSync;
  }

  @action
  public async updateDataSync(dataSync: DataSyncItem) {
    this.loading = true;
    const response = await ZipApi.updateDataSync(dataSync);
    const index = this._allDataSyncs.findIndex((item) => item.id === dataSync.id);
    if (index !== -1) {
      this._allDataSyncs[index] = response.dataSync;
    }
    this.loading = false;
  }

  @action
  public async deleteDataSync(dataSyncId: string) {
    await ZipApi.deleteDataSync(dataSyncId);
    this._allDataSyncs = this._allDataSyncs.filter((item) => item.id !== dataSyncId);
  }

  @computed
  public get paging() {
    return this._paging;
  }

  @action
  public setPaging(paging: DataTablePagingProps) {
    if (paging.rowStart <= 0) {
      paging.rowStart = 1;
    }
    this._paging = paging;
    this.fetch();
  }
}

export const dataSyncStore = new DataSyncStore();
