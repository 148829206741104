import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import styles from './DataSyncFormSection.module.sass';
import { DropDown, Label } from '@zaiusinc/hera';
import { dataSyncOdpObjectsStore } from '../../../../stores/DataSyncOdpObjectsStore';
import { DataSyncSource } from '../../../../types/DataSyncItem';
import { directoryStore } from '../../../../stores/DirectoryStore';
import { useObserver } from 'mobx-react-lite';
import ErrorMessage from '../ErrorMessage';
import { ValidateHandle } from './ValidateHandle';

export interface DataSyncFormSourceProps {
  initialValue: DataSyncSource;
  onChange: (value: DataSyncSource) => void;
}

const DataSyncFormSource = forwardRef<ValidateHandle, DataSyncFormSourceProps>((props, ref) => {
  const {initialValue, onChange} = props;
  const [source, setSource] = React.useState<DataSyncSource>({...initialValue});
  const [errors, setErrors] = React.useState<{ [key: string]: boolean }>({
    object_name: false,
  });

  useImperativeHandle(ref, () => ({
    validate: () => {
      setErrors({...errors, object_name: !source.object_name});
      return !!source.object_name;
    },
  }));

  useEffect(() => {
    dataSyncOdpObjectsStore.fetch();
  }, []);

  return useObserver(() => (
    <div className={styles.container}>
      <Label
        label="Source"
        subLabel="Select an external app as the data source. If your app isn’t listed,
          check the App Directory for available data integrations.">
        <></>
      </Label>

      <Label label="Object">
        <DropDown
          loading={dataSyncOdpObjectsStore.loading}
          initialContent="Select Object"
          value={source.object_name}
          items={dataSyncOdpObjectsStore.getObjectList()}
          width={390}
          onItemSelect={(item) => {
            const newSource = {...source, object_name: item.value, tracker_id: directoryStore.trackerId};
            setSource(newSource);
            onChange(newSource);
            setErrors({...errors, object_name: false});
          }}/>
        <ErrorMessage message="Please select an object" show={errors.object_name}/>
      </Label>

    </div>
  ));
});

export default DataSyncFormSource;
