import React from 'react';
import { Button, Intent } from '@blueprintjs/core';
import styles from './DataSyncListPage.module.sass';
import DataSyncList from './components/DataSyncList';
import { useHistory } from 'react-router-dom';

export const DataSyncListPage = () => {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.mainContainer}>
          <div className={styles.mainContainerRow}>
            <Button intent={Intent.PRIMARY} text="Add New" className={styles.addButton}
                    onClick={() => history.push('/data_syncs/new')}/>
          </div>
          <div><DataSyncList /></div>
        </div>
      </div>
    </div>
  );
};
