import React, { forwardRef, useImperativeHandle } from 'react';
import styles from './FieldMappings.module.sass';
import { DropDown, Label } from '@zaiusinc/hera';
import ErrorMessage from '../ErrorMessage';
import { ValidateHandle } from './ValidateHandle';

const scheduleIntervals = [
  {
    text: 'Every 10 minutes',
    value: '600s'
  },
  {
    text: 'Every 20 minutes',
    value: '1200s'
  },
  {
    text: 'Every 30 minutes',
    value: '1800s'
  },
  {
    text: 'Every 1 hour',
    value: '3600s'
  },
  {
    text: 'Every 2 hours',
    value: '7200s'
  },
  {
    text: 'Every 3 hours',
    value: '10800s'
  },
  {
    text: 'Every 4 hours',
    value: '14400s'
  },
  {
    text: 'Every 6 hours',
    value: '21600s'
  },
  {
    text: 'Every 12 hours',
    value: '43200s'
  },
  {
    text: 'Every 1 day',
    value: '86400s'
  },
  {
    text: 'Every 2 days',
    value: '172800s'
  }
];

export interface DataSyncScheduleSectionProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const DataSyncScheduleSection = forwardRef<ValidateHandle, DataSyncScheduleSectionProps>((props, ref) => {
  const {value, onChange, disabled} = props;
  const [errors, setErrors] = React.useState<{ [key: string]: boolean }>({
    interval: false,
  });

  useImperativeHandle(ref, () => ({
    validate: () => {
      setErrors({...errors, interval: !value});
      return !!value;
    },
  }));

  return (
    <div className={styles.container}>
      <Label label="Sync Schedule" subLabel="Set the frequency for this sync" disabled={disabled}>
        <DropDown
          disabled={disabled}
          initialContent="Select Interval"
          items={scheduleIntervals}
          value={value} width={390}
          onItemSelect={function noRefCheck(item: any) {
            onChange(item.value);
            setErrors({...errors, interval: false});
          }}/>
        <ErrorMessage message={'Please select an interval'} show={errors.interval} />
      </Label>
    </div>
  );
});

export default DataSyncScheduleSection;
