import React from 'react';
import styles from './DataSyncListPage.module.sass';

interface MainContainerProps {
  title: string;
  children: React.ReactNode;
  headerActions?: React.ReactNode;
}

const MainContainer: React.FC<MainContainerProps> = ({title, children, headerActions}) => {
  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.mainContainer}>
          <div className={styles.mainContainerRow}><h1 className={styles.calloutBody}>{title}</h1>
            {headerActions && <div className={styles.headerActions}>{headerActions}</div>}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainContainer;
