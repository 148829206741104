import { action, observable } from 'mobx';
import { ZipApi } from '../lib/ZipApi';
import { directoryStore } from './DirectoryStore';
import { SelectItem } from '@zaiusinc/hera';
import { ObjectDefinition, ObjectFieldDefinition } from '../types/DataSyncItem';

export class DataSyncCGStore {
  @observable public loading = false;
  @observable private _objectDefinitions: ObjectDefinition[] = [];

  @action
  public async fetch(instanceId: string) {
    this.loading = true;

    if (directoryStore.trackerId) {
      this._objectDefinitions = await ZipApi.listCGSchemas(directoryStore.trackerId, instanceId);
    }

    this.loading = false;
  }

  public getObjectList(): SelectItem[] {
    return this._objectDefinitions.map((object) => {
      return {
        text: object.object_name,
        value: object.object_name,
      };
    });
  }

  public getObjectFields(objectName: string): ObjectFieldDefinition[] {
    const object = this._objectDefinitions.find((obj) => obj.object_name === objectName);

    if (!object) {
      return [];
    }
    return object.fields;
  }
}

export const contentGraphStore = new DataSyncCGStore();
