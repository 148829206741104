import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DataSyncForm from './components/DataSyncForm';
import { useObserver } from 'mobx-react-lite';
import { showToasts } from '../../lib/toaster';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { dataSyncStore } from '../../stores/DataSyncStore';

const EditDataSyncPage = () => {
  const {dataSyncId} = useParams<{ dataSyncId: string }>();
  const history = useHistory();
  const [dataSync, setDataSync] = React.useState<any>(null);

  useEffect(() => {
    dataSyncStore.getDataSync(dataSyncId)
      .then((obj) => setDataSync(obj));
  }, [dataSyncId]);

  const onSubmit = async (obj: any) => {
    dataSyncStore.updateDataSync(obj)
      .then(() => {
        history.push('/data_syncs');
        showToasts([{intent: 'success', message: 'Data sync updated successfully'}]);
      })
      .catch((e) => showToasts([{intent: 'warning', message: `Failed to update data sync. ${e}`}]));
  };

  const onCancel = () => {
    history.push('/data_syncs');
  };

  return useObserver(() => {
    if (dataSyncStore.loading || !dataSync) {
      return <LoadingIndicator />;
    }

    return <DataSyncForm mode="edit" initialValues={dataSync} onSubmit={onSubmit} onCancel={onCancel}/>;
  });
};

export default EditDataSyncPage;
