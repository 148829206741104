import React, { useEffect } from 'react';
import styles from './DataSyncList.module.sass';
import { Icon, Intent, Menu, MenuItem, Popover } from '@blueprintjs/core';
import { dataSyncStore } from '../../../stores/DataSyncStore';
import { IconNames } from '@blueprintjs/icons';
import { useObserver } from 'mobx-react-lite';
import { showToasts } from '../../../lib/toaster';
import { Accordion, DataTablePagingProps, SearchInput } from '@zaiusinc/hera';
import { DataTablePaging } from '@zaiusinc/hera';
import { DataSyncItem } from '../../../types/DataSyncItem';
import { useHistory } from 'react-router-dom';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { directoryStore } from '../../../stores/DirectoryStore';
import { ZipApi } from '../../../lib/ZipApi';

const DataSyncList = () => {
  const history = useHistory();

  useEffect(() => {
    if (directoryStore.trackerId) {
      dataSyncStore.fetch()
        .catch((e) => {
          showToasts([{intent: 'danger', message: `Failed to fetch data syncs. ${e}`}]);
        });
    }
  }, []);

  return useObserver(() => {
    const renderEmptyState = () => (
      <div className={styles.emptyDataSyncList}>
        <Icon icon={IconNames.SEARCH} iconSize={64}/>
        <p>No data syncs were found</p>
      </div>
    );

    const renderMenu = (data) => (
      <Menu className={styles.menu}>
        <MenuItem htmlTitle="menu-item" text="Executions" onClick={() => history.push(`/data_syncs/${data.id}/executions`) }/>
        <MenuItem htmlTitle="menu-item" text="Trigger now" onClick={() => handleTriggerSync(data.id)}/>
        <MenuItem
          htmlTitle="menu-item"
          text={data.enabled ? 'Pause' : 'Start'}
          onClick={() => handleToggleDataSync(data.id)}
        />
        <MenuItem htmlTitle="menu-item" text="Edit" onClick={() => history.push(`/data_syncs/${data.id}`) }/>
        <MenuItem htmlTitle="menu-item" text="Delete" onClick={() => handleDeleteDataSync(data.id)}/>
      </Menu>
    );

    const handleToggleDataSync = async (id: string) => {
      try {
        await dataSyncStore.toggleDataSync(id);
      } catch (error) {
        showToasts([{intent: 'warning', message: `Cannot start/pause data sync. ${error}`}]);
      }
    };

    const handleDeleteDataSync = async (id: string) => {
      try {
        await dataSyncStore.deleteDataSync(id);
        showToasts([{intent: 'success', message: 'Data sync deleted successfully'}]);
      } catch (error) {
        showToasts([{intent: 'warning', message: `Cannot delete data sync. ${error}`}]);
      }
    };

    const handleTriggerSync = async (id: string) => {
      if (await ZipApi.triggerSync(id)) {
        showToasts([{intent: 'success', message: 'Sync triggered successfully'}]);
      } else {
        showToasts([{intent: 'warning', message: 'Failed to trigger sync'}]);
      }
    };

    const renderDataSyncCardHeader = (data: DataSyncItem) => (
      <div key={`${data.id}-head`} className={styles.cardHeader}>
        <div className={styles.cardTitle}>
          <div className={styles.syncIcons}>
            <img src="/assets/CMP.svg" alt="CMS Icon" className={styles.icon}/>
            <Icon icon={IconNames.CARET_RIGHT}/>
            <img src="/assets/CMS.svg" alt="CMS Icon" className={styles.icon}/>
          </div>
          <span>{data.name}</span>
        </div>
        <div className={styles.cardLastStatus}>
          {getLastSyncStatus(data)}
          <div onClick={(e) => e.stopPropagation()}>
            <Popover content={renderMenu(data)} placement="bottom">
              <button className={styles.cardEllipsis}>...</button>
            </Popover>
          </div>
        </div>
      </div>
    );

    const getLastSyncStatus = (data: DataSyncItem) => {
      const lastExecution = data.lastExecution;
      const ActiveLabe = <span style={{ width: '51px' }}>
        <b>{data.enabled ? 'Active' : 'Paused'}</b>
      </span>;
      if (!lastExecution) {
        return <div className={styles.cardStatusMessage}>
          <Icon icon={IconNames.FULL_CIRCLE} intent={Intent.WARNING}/>
          {ActiveLabe}
          <span>It has never been executed</span>
        </div>;
      }
      const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      };
      const formattedDate = new Date(lastExecution.created_at).toLocaleString('en-US', options);
      return <div className={styles.cardStatusMessage}>
        {lastExecution.status === 'COMPLETED' ?
        <Icon icon={IconNames.FULL_CIRCLE} intent={Intent.SUCCESS}/> :
        <Icon icon={IconNames.ERROR} intent={Intent.DANGER}/>}
        {ActiveLabe}
        <span>Last synced on {formattedDate}</span>
      </div>;
    };

    const renderDataSyncCard = (data: DataSyncItem) => (
      <Accordion
        key={data.id}
        header={renderDataSyncCardHeader(data)}
        startOpen={false}
        minimal={true}
        className={styles.card}
      >
        <div className={styles.cardDetails}>
          <p>
            {data.source.object_name}
            <Icon icon={IconNames.CARET_RIGHT}/>
            {data.destination.object_name}
          </p>
          <table className={styles.carDetailsTable}>
            <thead>
              <tr>
                <th>TOTAL SYNC</th>
                <th>FAILING</th>
                <th className={styles.carDetailsTableError}>LAST ERROR</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>0</td>
                <td>0</td>
                <td>
                  {data.lastExecution?.status === 'COMPLETED' ?
                  <p>None</p> :
                  <p style={{color: 'red'}}>{data.lastExecution?.message}</p>}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Accordion>
    );

    if (dataSyncStore.loading) {
      return <LoadingIndicator />;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.listTop}>
          <SearchInput
            className={styles.searchInput}
            onChange={(value) => dataSyncStore.searchFilter = value.trim()}
            placeholder="Find Data Sync"
          />
          <DataTablePaging
            rowStart={dataSyncStore.paging.rowStart}
            rowCount={dataSyncStore.paging.rowCount}
            total={dataSyncStore.paging.total}
            onPaging={(pagingProps: DataTablePagingProps) => {
              dataSyncStore.setPaging(pagingProps);
            }}
            hideDropDown={false}
          />
        </div>
        {dataSyncStore.dataSyncs.length === 0 ? renderEmptyState() : dataSyncStore.dataSyncs.map(renderDataSyncCard)}
      </div>
    );
  });
};

export default DataSyncList;
