import {Button, Callout, Icon, Intent, Tag} from '@blueprintjs/core';
import {IconNames} from '@blueprintjs/icons';
import {useObserver} from 'mobx-react-lite';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {addToQuery} from '../../lib/history';
import {titleize} from '../../lib/titleize';
import {showToasts} from '../../lib/toaster';
import {AppDetailStore} from '../../stores/AppDetailStore';
import {directoryStore} from '../../stores/DirectoryStore';
import styles from './AppInfoCard.module.sass';
import {UninstallButton} from './UninstallButton';

interface Props {
  detailStore: AppDetailStore;
}

export const AppInfoCard = ({detailStore}: Props) => {
  const internalApps = ['b2b_commerce', 'cms', 'commerce_cloud', 'cmp_integration', 'content_intelligence', 'optimizely_campaign'];
  const history = useHistory();

  const onInstall = async () => {
    if (await detailStore.install()) {
      addToQuery(history, {tab: 'settings'});
      showToasts([{intent: 'success', message: 'Installed!'}]);
    } else {
      showToasts([{intent: 'danger', message: 'Failed to install.'}]);
    }
  };

  return useObserver(() => {
    const {loading, detail, version, installed, installing} = detailStore;
    if (loading || !detail) {
      return <div className={styles.container} />;
    }

    return (
      <div className={styles.container}>
        <div className={styles.row}>
          <img className={styles.logoMark} src={detail.logo_url} alt="App Logo" />
          <div>
            <span className={styles.appName}>{detail.display_name}</span>
            <span className={styles.version}>v{version}</span>
          </div>
          <div className={styles.vendor}>by {titleize(detail.vendor)}</div>
          <div className={styles.buttons} style={{display: directoryStore.trackerId ? 'flex' : 'none'}}>
            <Button
              intent={installed ? Intent.SUCCESS : Intent.PRIMARY}
              disabled={installed || directoryStore.requiresAccountUpgrade(detailStore.appId)}
              loading={installing}
              onClick={onInstall}
            >{installed ?
              renderInstallButtonContents(detailStore) :
              'Install App'
              }</Button>
            <UninstallButton history={history} detailStore={detailStore} />
          </div>
        </div>
        {directoryStore.requiresAccountUpgrade(detailStore.appId) &&
          <div>
            <div className={styles.row}>
              <Callout intent={Intent.PRIMARY} icon={null}>
                Available with { directoryStore.getAccountType() } upgrade
                <br/>
                <a href="mailto:CSM.Leaders@optimizely.com">Contact Sales</a>
              </Callout>
            </div>
            <div className={styles.divider} />
          </div>
        }
        <div className={styles.categories}>
          {renderCategories(detailStore)}
        </div>
        <div className={styles.divider}></div>
        <div className={styles.note} style={{display: internalApps.includes(detailStore.appId) ? 'none' : 'block'}}>
          <div>By installing this app you accept <a rel="noopener noreferrer" href="https://www.optimizely.com/legal/third-party-add-ons-platform-integration-terms" target="_blank">Third-Party Add-Ons & Platform Integration Terms</a></div>
        </div>
        <div />
        {renderLinks(detailStore)}
      </div>
    );
  });
};

const renderCategories = (store: AppDetailStore) => (
  store.detail!.categories.map((category) => <Tag key={category}>{category}</Tag>)
);

const renderLinks = (store: AppDetailStore) => (
  <div className={styles.links}>
    <a
      rel="noopener noreferrer"
      href={store.detail!.support_url || 'https://apps.zaius.com/'}
      target="_blank"
    >
      App Help
    </a>
  </div>
);

const renderInstallButtonContents = (store: AppDetailStore) => (
  store.sameVersionInstalled ?
    <span>Installed <Icon icon={IconNames.TICK} className={styles.check} /></span> :
    <span>Installed [{store.installedVersion}]<Icon icon={IconNames.TICK} className={styles.check} /></span>
);
