import {Button, Intent} from '@blueprintjs/core';
import {IconNames} from '@blueprintjs/icons';
import {Confirm} from '@zaiusinc/hera';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {addToQuery} from '../../lib/history';
import {showToasts} from '../../lib/toaster';
import {AppDetailStore} from '../../stores/AppDetailStore';
import { directoryStore } from '../../stores/DirectoryStore';
interface Props {
  detailStore: AppDetailStore;
  history: ReturnType<typeof useHistory>;
}

@observer
export class UninstallButton extends React.Component<Props> {
  @observable public showUninstallModal: boolean = false;
  @observable public showCannotUninstallModal: boolean = false;
  @observable public confirmationMessage: string = '';
  @observable public loading: boolean = false;

  public render() {
    const {installed, sameVersionInstalled, uninstalling, detail} = this.props.detailStore;

    if (!installed || !sameVersionInstalled) {
      return null;
    }

    const appName = detail ? detail.display_name : 'this app';

    return (
      <div>
        <Button
          minimal
          intent={Intent.NONE}
          disabled={uninstalling}
          onClick={this.onUninstallClick}
          loading={uninstalling || this.loading}
        >
          Uninstall App
        </Button>
        <Confirm
          intent="danger"
          icon={IconNames.WARNING_SIGN}
          confirmText="Uninstall"
          isOpen={this.showUninstallModal}
          onClose={this.onCloseModal}
          onConfirm={this.onConfirmUninstall}
          title={`Uninstall ${appName}?`}
        >
          <p>{this.confirmationMessage}</p>
          <ul>
            <li>Uninstalling the app will disable all features of the app.</li>
            <li>{ directoryStore.getAccountType() } data generated by the app will NOT be deleted.</li>
          </ul>
        </Confirm>
        <Confirm
          intent="danger"
          icon={IconNames.WARNING_SIGN}
          cancelDisabled={true}
          confirmText="OK"
          isOpen={this.showCannotUninstallModal}
          onClose={this.onCloseModal}
          onConfirm={this.onCloseModal}
          title={`Unable to uninstall ${appName}`}
        >
          {this.confirmationMessage}
        </Confirm>
      </div>
    );
  }

  private onUninstallClick = async () => {
    this.loading = true;

    try {
      const {uninstallable, message} = await this.props.detailStore.canUninstall();

      if (uninstallable) {
        this.showUninstallModal = true;
        this.confirmationMessage = message || this.getDefaultConfirmationMessage();
      } else {
        this.showCannotUninstallModal = true;
        this.confirmationMessage = message || 'Sorry, this app cannot be uninstalled at this time.';
      }
    } catch (e) {
      console.error(e);
      const message = 'There was an error while checking if the app can be uninstalled.';
      showToasts([{intent: Intent.DANGER, message}]);
    } finally {
      this.loading = false;
    }
  }

  private getDefaultConfirmationMessage(): string {
    const detail = this.props.detailStore.detail;
    const appName = detail ? detail.display_name : 'this app';
    return `Are you sure you want to uninstall the ${appName} app?`;
  }

  private onCloseModal = () => {
    this.showUninstallModal = false;
    this.showCannotUninstallModal = false;
  }

  private onConfirmUninstall = async () => {
    const success = await this.props.detailStore.uninstall();
    addToQuery(this.props.history, {tab: 'overview'});
    this.showUninstallModal = false;

    const {detail} = this.props.detailStore;
    const appName = detail ? detail.display_name : 'This app';
    if (success) {
      showToasts([{intent: 'success', message: `${appName} was uninstalled successfully`}]);
    }
  }
}
