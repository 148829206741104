import React, { useEffect, useState } from 'react';
import DataSyncExecutionList from './components/DataSyncExecutionList';
import { useParams } from 'react-router-dom';
import MainContainer from './MainContainer';
import DataSyncDetailsStore from '../../stores/DataSyncDetailsStore';
import { showToasts } from '../../lib/toaster';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { useObserver } from 'mobx-react-lite';

export const DataSyncExecutionsPage = () => {
  const {dataSyncId} = useParams<{ dataSyncId: string }>();
  const [dataSyncDetailsStore, setDataSyncDetailsStore] = useState<DataSyncDetailsStore | null>(null);

  useEffect(() => {
    const store = new DataSyncDetailsStore(dataSyncId);
    setDataSyncDetailsStore(store);
    store.getExecutions()
      .catch((e) => {
        showToasts([{intent: 'danger', message: `Failed to fetch data sync executions. ${e}`}]);
      });
  }, [dataSyncId]);

  return useObserver(() => {

    const isReady = dataSyncDetailsStore !== null;
    const name = dataSyncDetailsStore?.dataSync?.name;
    return (
      <MainContainer title={name ? name + ' Executions' : ''}>
        {isReady ? <DataSyncExecutionList detailStore={dataSyncDetailsStore} /> : <LoadingIndicator /> }
      </MainContainer>
    );
  });
};
