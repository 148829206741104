import {observable} from 'mobx';
import {HostPageMessenger} from '../lib/HostPageMessenger';
import {UserFeatures} from '../types/UserFeatures';
import {AppConstants} from '../types/AppConstants';
import AccountType = AppConstants.AccountType;
export class DirectoryStore {
  @observable public trackerId?: string;
  @observable public userFeatures?: UserFeatures;
  @observable public permittedApps?: string[];

  public isOptiHubFlag: string = 'isOptiHub';

  constructor() {
    if (process.env.REACT_APP_TRACKER_ID) {
      this.trackerId = process.env.REACT_APP_TRACKER_ID;
    }
    HostPageMessenger.on('setTracker', (tracker) => {
      this.trackerId = tracker;
    });
    HostPageMessenger.on('ready', () => HostPageMessenger.send('getTracker'));
    HostPageMessenger.on('userFeatures', (data: UserFeatures) =>  {
      this.userFeatures = data;
      this.permittedApps =
        data?.variants?.setting_permitted_ocp_apps
          ? data?.variants.setting_permitted_ocp_apps.split(',')
          : [];
    });
  }

  public hasFeatureFlag = (flag: string) => {
    return this.userFeatures?.features?.[flag] || false;
  }

  public requiresAccountUpgrade = (appId: string) => {
    return this.permittedApps?.length !== 0 && !this.permittedApps?.includes(appId);
  }

  public getAccountType = () => {
    return this.hasFeatureFlag(this.isOptiHubFlag) ? AccountType.HUB : AccountType.ODP;
  }
}

export const directoryStore = new DirectoryStore();
